import React, { PropsWithChildren, ReactElement, HTMLAttributes, useCallback, useEffect, useRef, useMemo, FCC } from 'react';
import { useTable, useSortBy, usePagination, Column, CellProps, IdType, Row, Cell, HeaderGroup, useRowSelect } from 'react-table';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { FetchDataFunc, DEFAULT_API_PAGINATION } from '@/common/utils';
import { LoadingSection } from '@/common/components/loading-section';
import { useStyles } from '@/styles/hooks';
import { Icon } from '../icon';
import { Checkbox } from '../form-controls-deprecated';
import { Paginator, PaginatorData, PaginatorProps } from './components/paginator';
import { HorizontalScroll, HorizontalScrollProps } from './components/horizontal-scroll';
import { useColumnHiding, HidingColumnProps } from './components/column-hiding';
import { OptionsDropdown } from './components/options-dropdown';
import { SelectActions } from './components/select-actions';
import { RowClickProps, useCustomRowSelect, useRowClick } from './hooks';
import { tableStyles, loadMoreStyles } from './styles';
import { ButtonTheme } from '../button';

export type HidableColumn<T extends {} = {}> = Column<T> & HidingColumnProps;

export type TableProps<T extends {} = {}> = Pick<HTMLAttributes<HTMLDivElement>, 'className'> &
  HorizontalScrollProps &
  RowClickProps<T> &
  Partial<PaginatorData> & {
    columns: Column<T>[] | HidableColumn<T>[];
    data: T[];
    pageCount?: number;
    isLoadingMore?: boolean;
    isDraggable?: boolean;
    hideDefaultPagination?: boolean;
    noPagination?: boolean;
    manualPagination?: boolean;
    sort?: string[];
    fetchData?: FetchDataFunc;
    withRowSelect?: boolean;
    maxSelectedRows?: number;
    onRowSelect?: (selectedRows: Row<T>[], selectedRowsIds: Record<IdType<T>, boolean> | undefined) => void;
    selectedRowsIds?: Record<IdType<T>, boolean> | undefined;
    onRowSelectCancel?: () => void;
    onRowSelectConfirm?: () => void;
    itemsTranslation?: string;
    actionButtonText?: string;
    actionButtonType?: ButtonTheme;
    handleDragDrop?: (startIndex: number, endIndex: number) => void;
    canScrollHorizontally?: boolean;
    columnsHiding?: boolean;
    name?: string;
    disablePagination?: boolean;
    isRowSelectStylesVisible?: boolean;
  };

export function Table<T extends {} = {}>({
  columns,
  data,
  pageCount: basePageCount,
  pageIndex,
  pageSize,
  hideDefaultPagination,
  fetchData,
  handleDragDrop,
  sort,
  isDraggable,
  isLoadingMore = false,
  noPagination = false,
  manualPagination = true,
  canScrollHorizontally,
  scrollTo,
  columnsHiding,
  className,
  withRowSelect,
  maxSelectedRows,
  onRowSelect,
  selectedRowsIds,
  itemsTranslation,
  actionButtonText,
  actionButtonType,
  onRowSelectCancel,
  onRowSelectConfirm,
  onRowClick,
  selectedRow,
  name,
  disablePagination,
  isRowSelectStylesVisible = false,
}: PropsWithChildren<TableProps<T>>): ReactElement | null {
  const [t] = useTranslation();
  const tableRef = useRef<HTMLTableElement>(null);
  const isPageIndexInitialized = useRef(false);
  const preventFetchData = useRef(false);
  const preventFirstFetch = useRef(false);
  const TableStyles = useStyles(tableStyles);
  const LoadMoreStyles = useStyles(loadMoreStyles);

  const withDraggable = useMemo(() => !withRowSelect && isDraggable, [withRowSelect, isDraggable]);

  const initialState = {
    pageIndex: pageIndex || DEFAULT_API_PAGINATION.page,
    pageSize: pageSize || DEFAULT_API_PAGINATION.size,
    sortBy: (sort && sort.map(item => item.split(',')).map(item => ({ id: item[0], desc: item[1] === 'DESC' }))) || [],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setPageSize,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    pageOptions,
    pageCount,
    page,
    state,
    selectedFlatRows,
    state: { selectedRowIds },
    allColumns,
    setHiddenColumns,
  } = useTable<T>(
    {
      columns,
      data,
      manualPagination,
      pageCount: basePageCount || -1,
      manualSortBy: true,
      initialState: withRowSelect
        ? {
            ...initialState,
            selectedRowIds: selectedRowsIds,
          }
        : initialState,
    },
    ...[useSortBy, usePagination, useRowSelect, useCustomRowSelect(maxSelectedRows)],
    hooks => {
      hooks.visibleColumns.push(columns =>
        withRowSelect
          ? [
              {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div className='row-selection-checkbox'>
                    <Checkbox onChange={getToggleAllRowsSelectedProps().onChange} checked={getToggleAllRowsSelectedProps().checked} />
                  </div>
                ),
                Cell: ({ row, selectedFlatRows }: CellProps<T>) => {
                  const { onChange, checked } = row.getToggleRowSelectedProps();
                  return (
                    <div className='row-selection-checkbox'>
                      <Checkbox onChange={onChange} checked={checked} disabled={!checked && selectedFlatRows.length === maxSelectedRows} />
                    </div>
                  );
                },
              },
              ...columns,
            ]
          : columns
      );
    }
  );

  const { handleRowClick, getRowClassName } = useRowClick<T>({ onRowClick, selectedRow, isRowSelectStylesVisible });

  const handleLoadMore = useCallback(() => nextPage(), [nextPage]);

  const paginatorData: PaginatorProps = {
    ...state,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    disabled: disablePagination,
  };

  useEffect(() => {
    if (onRowSelect !== undefined) {
      onRowSelect(selectedFlatRows, selectedRowIds);
    }
  }, [onRowSelect, selectedFlatRows, selectedRowIds]);

  useEffect(() => {
    preventFirstFetch.current = true;
  }, []);

  useEffect(() => {
    if (isPageIndexInitialized.current) {
      if (pageIndex !== undefined && state.pageIndex !== pageIndex) {
        gotoPage(pageIndex);
        preventFetchData.current = true;
      }
    } else if (pageSize === -1) {
      isPageIndexInitialized.current = true;
      preventFetchData.current = true;
    } else {
      isPageIndexInitialized.current = true;
      preventFetchData.current = false;
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (preventFirstFetch.current) {
      preventFirstFetch.current = false;
    } else if (!preventFetchData.current) {
      if (fetchData !== undefined) {
        const newSortValue = state.sortBy.map(item => [item.id, item.desc ? 'DESC' : 'ASC'].join());
        const sortChanged = newSortValue.join() !== (sort || []).join();

        fetchData({
          sort: newSortValue,
          page: sortChanged ? 0 : state.pageSize === pageSize ? state.pageIndex : 0,
          size: state.pageSize,
        });
      }
    } else {
      preventFetchData.current = false;
    }
  }, [state.pageIndex, state.pageSize, state.sortBy]);

  const handleDragEnd = (result: DropResult) => {
    if (handleDragDrop) {
      const { source, destination } = result;
      if (!destination) return;
      handleDragDrop(source.index, destination.index);
    }
  };

  const { columnHidingDialog, onHideColumnDialogOpen } = useColumnHiding(allColumns, setHiddenColumns, columnsHiding, withRowSelect, name);

  const mapHeaders = useCallback(
    (column: HeaderGroup<T>, index: number, array: HeaderGroup<T>[]) => {
      const Wrapper: FCC<{ className?: string }> = ({ className, children }) => (
        <th id={column.id} {...column.getHeaderProps(column.getSortByToggleProps())}>
          <div className={cn(['header-wrapper', className])}>{children}</div>
        </th>
      );
      if (columnsHiding && index === array.length - 1) {
        return (
          <Wrapper key={index} className={'header-wrapper__align-left'}>
            <OptionsDropdown isVisible={columnsHiding} onClickHandlers={{ onHideColumnDialogOpen }} />
          </Wrapper>
        );
      }
      return (
        <Wrapper key={index}>
          {column.render('Header')}
          {column.canSort && !column.isSorted && <Icon name='mdi-menu-swap' className='sorting-indicator sorting-indicator--sortable' />}
          {column.isSorted &&
            (column.isSortedDesc ? <Icon name='mdi-menu-down' className='sorting-indicator' /> : <Icon name='mdi-menu-up' className='sorting-indicator' />)}
        </Wrapper>
      );
    },
    [columnsHiding, onHideColumnDialogOpen]
  );

  return (
    <HorizontalScroll tableElement={tableRef.current} canScrollHorizontally={canScrollHorizontally} scrollTo={scrollTo}>
      <div css={TableStyles.styles} className={cn(className, withRowSelect ? 'with-row-select' : '')}>
        {columnHidingDialog}

        <table {...getTableProps()} ref={tableRef}>
          <thead>
            {headerGroups.map(headerGroup => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {withDraggable && (
                  <th colSpan={1} role='columnheader' onClick={handleRowClick(-1)}>
                    <div className='header-wrapper'></div>
                  </th>
                )}
                {headerGroup.headers.map(mapHeaders)}
              </tr>
            ))}
          </thead>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='table-body'>
              {provided => (
                <tbody ref={provided.innerRef} {...getTableBodyProps()} {...provided.droppableProps}>
                  {page.map((row: Row<T>, i) => {
                    prepareRow(row);
                    const id = (row.original as any).id?.toString() || i.toString();
                    return (
                      <Draggable draggableId={id} key={id} index={row.index}>
                        {provided => {
                          return (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...row.getRowProps()}
                              onClick={handleRowClick(i, row)}
                              className={getRowClassName(i)}
                            >
                              <td {...provided.dragHandleProps} className={cn('drag-handle', { hidden: !withDraggable })}>
                                <Icon name={'mdi-reorder-black'} className='reorder' />
                              </td>
                              {row.cells.map((cell: Cell<T>) => {
                                return (
                                  // eslint-disable-next-line react/jsx-key
                                  <td {...cell.getCellProps()}>
                                    {cell.render('Cell', {
                                      dragHandleProps: provided.dragHandleProps,
                                    })}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>

        {withRowSelect && (
          <SelectActions
            selectedFlatRows={selectedFlatRows}
            maxSelectedRows={maxSelectedRows}
            itemsTranslation={itemsTranslation}
            actionButtonText={actionButtonText}
            actionButtonType={actionButtonType}
            onRowSelectConfirm={onRowSelectConfirm}
            onRowSelectCancel={onRowSelectCancel}
          />
        )}

        {!noPagination && hideDefaultPagination && handleLoadMore !== undefined && canNextPage && (
          <div css={LoadMoreStyles.styles}>
            {isLoadingMore ? (
              <LoadingSection size='small' />
            ) : (
              <button onClick={handleLoadMore} disabled={isLoadingMore} className='load-more-button'>
                <span className='label'>{t('button.loadMore')}</span>
                <Icon name={'mdi-chevron-down'} className='icon' />
              </button>
            )}
          </div>
        )}

        {!noPagination && !hideDefaultPagination && <Paginator {...paginatorData} />}
      </div>
    </HorizontalScroll>
  );
}
